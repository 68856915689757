import React from 'react';

const ThankYouPage = () => {
    return (
        <div className="flex flex-col justify-center items-center h-screen p-4">
            {/* Title */}
            <h1 className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-6 text-center">
                Eastern Estate Construction & Developers
            </h1>

            {/* Thank You Heading */}
            <h2 className="text-xl lg:text-2xl font-semibold text-onyx mb-4 text-center">
                THANK YOU!
            </h2>

            {/* SVG Check Symbol */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16 lg:w-20 lg:h-20 mb-4 text-secondary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="3"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                />
            </svg>

            {/* Thank You Text */}
            <p className="text-lg lg:text-xl text-red font-bold mb-4 text-center">
                THANK YOU FOR REGISTERING YOUR INTEREST.
            </p>

            {/* Additional Text */}
            <p className="text-base lg:text-lg text-gray-700 text-center">
                One of our sales representatives will be in touch with you shortly.
            </p>

            {/* Contact Information */}
            <p className="mt-4 text-sm lg:text-base text-gray-700 text-center">
                Need help? Call our award-winning support team at{' '}
                <a href="tel:+917026373753" className="text-[#0000EE] underline">
                    +91 99393 66036
                </a>
            </p>

            {/* Continue Button */}
            <a
                href="/diamondcitycuttackbhubaneswar"
                className="mt-4 text-sm lg:text-base text-[#0000EE] underline"
            >
                Continue to Page
            </a>
        </div>
    );
};

export default ThankYouPage;

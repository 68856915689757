import React from 'react';
import { LiaPhoneVolumeSolid } from "react-icons/lia";

const ContactButton = ({ className }) => {
    const handleCall = (primaryNumber, fallbackNumber) => {
        const proceedWithFallback = window.confirm(
            `If the call to ${primaryNumber} doesn't go through, do you want to try calling ${fallbackNumber}?`
        );

        if (proceedWithFallback) {
            window.location.href = `tel:${fallbackNumber}`;
        }
    };
    return (
        <div className={`${className} flex justify-center items-center mb-4`}>
            <div className="rounded-full p-1">
                {/* Primary Number */}
                <button
                    onClick={() => handleCall("+917281000091", "+919876543210")}
                    className="bg-onyx text-white flex items-center justify-center px-4 py-2 rounded-full shadow-lg"
                >
                    <LiaPhoneVolumeSolid className="text-yellow-300 animate-pulse my-1 text-2xl" />
                    <span className="hidden lg:flex font-semibold text-lg pl-2">
                        +91-7281000091
                    </span>
                </button>
            </div>
        </div>
    );
};

export default ContactButton;
